// Args are the link mode state and the function that sets it
export default function LinkModeButton( { linkMode, setLinkMode } ) {
    

    function toggleLinkMode() {
        if (linkMode == "current tab") {
            setLinkMode("new tab");
        }
        else if (linkMode == "new tab") {
            setLinkMode("current tab");
        }
    }


    const linkModeCapitalized = linkMode.charAt(0).toUpperCase() + linkMode.slice(1);

    return (
        <div id="linkModeButton" onClick={toggleLinkMode} style={{ backgroundColor: (linkMode == "new tab" ? "#E04948" : null)}}>
            Link mode: <br /><b>{linkModeCapitalized}</b>
        </div>
    );
}