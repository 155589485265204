import { useEffect, useRef, useState } from "react"
import Fuse from "fuse.js";




// Custom hook for handling click events outside a specific component
function useOnclickOutside(ref, handler) {
    useEffect(() => {
        const listener = (event) => {
            // Check if the click occurred outside the referenced element
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler();
        };
        document.addEventListener("mousedown", listener);
        // Cleanup when component unmounts
        return () => {
            document.removeEventListener("mousedown", listener);
        };
    }, [ref, handler]);

};



export default function ItemSearchBar({ linkMode, itemData }) {


    const [barContent, setBarContent] = useState("");
    // Options for the Fuse search
    const options = {
        keys: ['*'], // Search in all keys
        tokenize: true, // Disregards order of matching strings
        threshold: 0.5,
        ignoreCase: true,
    };
    const numResults = 4; // Number of results we want to get from the search
    const resultDelay = 300 // Delay between last typed character and showing the search results (ms)
    const fuse = new Fuse(Object.keys(itemData), options);
    
    
    // Component is re-rendered every time the search bar
    
    // changes, which would reset a regular let. Therefore
    // we use a ref for timeoutID
    let timeoutID = useRef(null);
    
    
    let [results, setResults] = useState(null)
    let [showResults, setShowResults] = useState(false);
    const dropdownRef = useRef();

    function openResults() {
        setShowResults(true);
        console.log("Search results opened")
    }

    function closeResults() {
        setShowResults(false);
        console.log("Search results opened")
    }


    function handleItemSearchFieldFocus() {
        openResults();
        console.log("showResults set to true")
    }

    useOnclickOutside(dropdownRef, closeResults);

    // Performs a fuzzy search on the keys of items.json
    // and places them in the results state
    function getSearchResults(query) {
        const searchResults = fuse.search(query).slice(0, numResults);
        searchResults.map(item => searchResults[item] = itemData[item]);
        setResults(searchResults);
        return searchResults;
    }


    // Wraps the search function and ensures its not triggered
    // until after the user has stopped typing for a bit
    const debounce = (func, delay) => {
        // console.log("TimeoutID pre: " + timeoutID.current)

        // If there's an existing timeout, clear it
        // so they don't stack
        return function (...args) {
            if (timeoutID.current) {
                clearTimeout(timeoutID.current);
                // console.log("Cleared timeout " + timeoutID.current)
            }
            // Set a new timeout for the function
            timeoutID.current = setTimeout(() => {
                // Call the original function with provided args
                func.apply(this, args);
            }, delay);
            // console.log("TimeoutID post: " + timeoutID.current);
        };
    };

    const getSearchResultsDebounced = debounce(getSearchResults, resultDelay);

    // Event handler for when the content of the search bar changes
    // Handles BOTH updating the contents of the search bar, and
    // updating the search results
    const updateSearch = (event) => {
        const value = event.target.value;
        getSearchResultsDebounced(value);
        setBarContent(value);
    }


    let unpricedString = "This item is unpriced on BP.tf";
    // We change the colour of the item values
    // depending on their currencies

    let currencyColor = {
        "keys": "yellow",
        "metal": "lightgrey",
        "usd": "green",
        "": "grey", // Unpriced
    }

    return (
        <div id="itemSearch">
            <input type="text" id="itemSearchField" placeholder="Search for an item..." value={barContent} onChange={updateSearch} onFocus={handleItemSearchFieldFocus}></input>
            <label htmlFor="itemSearchField" className="placeholder"></label>

            {/* <div id="savedResultsButton">V</div> */}

            {/* Hovering over the dropdown needs to keep it open, or links won't work */}
            <div ref={dropdownRef} id="searchResultsDropdown" className="dropdown" style={{ display: showResults ? "flex" : "none" }}>
                {results ? results.map((item, index) => ( // Can only map arrays
                    <div id={item} key={index} className="dropdownEntry">
                        <div className="resultName">
                            {item.item}
                        </div>
                        <div className="resultValue" style={{ color: currencyColor[itemData[item.item].currency] }}>
                            {itemData[item.item].value !== '' ? (
                                `${itemData[item.item].value} ${itemData[item.item].currency}`
                            ) : (
                                <i>{unpricedString}</i>
                            )}
                        </div>

                        <div className="resultLinks">
                            <a className="bptfLink" href={itemData[item.item].bptf_link} target={linkMode == "new tab" ? "_blank" : null}> <button>BP.tf</button></a>
                            <a className="scmLink" href={itemData[item.item].scm_link} target={linkMode == "new tab" ? "_blank" : null}><button>SCM</button></a>
                            <a className="mptfLink" href={itemData[item.item].mptf_link} target={linkMode == "new tab" ? "_blank" : null}><button>MP.tf</button></a>
                        </div>
                    </div>
                )) : null}
            </div>
        </div>
    )
}