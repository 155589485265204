import keyIcon from '../assets/keyicon.png';


export default function KeyPriceDisplay({ keyRate, linkMode }) {

    return (
        <a href='https://backpack.tf/stats/Unique/Mann%20Co.%20Supply%20Crate%20Key/Tradable/Craftable' target={linkMode == "new tab" ? "_blank" : null} style={{ all: "unset"}}>
            <div id="keyPriceDisplay">
                <img src={keyIcon} /> ={keyRate} ref
            </div>
        </a>
    )
}