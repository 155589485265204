// import './App.css';
import logo from './assets/tftoolslogo.png';
import CurrencyCalculator from "./components/CurrencyCalc";
import ItemSearchBar from "./components/ItemSearchBar";
import ShortcutTab from './components/ShortcutTab';
import LinkModeButton from './components/LinkModeButton';
import SteamIDPopup from "./components/SteamIDPopup";
import { Component, useEffect, useState } from 'react';

function App() {


  const storedLinkMode = localStorage.getItem("linkMode");
  console.log("Curently stored linkmode: " + storedLinkMode);
  // storedLinkMode is null (false) if data isn't  already saved
  const initialLinkMode = storedLinkMode ? storedLinkMode : "current tab";
  const [linkMode, setLinkMode] = useState(initialLinkMode);
  // Update localStorage whenever linkMode changes
  useEffect(() => {
    localStorage.setItem("linkMode", linkMode);
  }, [linkMode]);


  const storedSteamID = localStorage.getItem("userSteamID");
  console.log("Currently stored SteamID: " + storedSteamID);
  // Start with blank string as ID if none is saved
  const initialSteamID = storedSteamID ? storedSteamID : "";
  const [userSteamID, setUserSteamID] = useState(initialSteamID)
  // Update localStorage whenever storedSteamID changes
  useEffect(() => {
    localStorage.setItem("userSteamID", userSteamID);
  }, [userSteamID]);



  const [steamIDPopupIsOpen, setSteamIDPopupIsOpen] = useState(false);





  // Fetches data from items.json

  const [itemData, setItemData] = useState({});
  
  useEffect(() => {
    const fetchData = async () => {
      console.log("Data fetch triggered")
      try {
        const response = await fetch('/data/items.json');
        // console.log(response);
        if (!response.ok) {
          // Handle non-successful response (e.g., 404 Not Found)
          throw new Error(`Failed to fetch data: ${response.statusText}`);
        }
        const data = await response.json();
        setItemData(data);
        // console.log('Data:', data);
      } catch (error) {
        // Handle fetch or parsing errors
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  // Make sure to change this if the "unique" part is removed!
  // Keys are very often priced with a 1 scrap range, so to simplify
  // calculations, only get the low value

  // Done in two lines to avoid type error when itemData is still an empty object
  const keyData = itemData["Unique Mann Co. Supply Crate Key"] 
  const keyValue = keyData ? parseFloat(itemData["Unique Mann Co. Supply Crate Key"]["value"].split("-")[0]) : null;


  // App was supposed to fade in on page load, but "load" and "DOMContentLoaded"
  // events caused compatibility errors in chromium. Oh well :(

  return (
    <div id="App" className={`AppVisible`}>
      <PageHeader />
      <LinkModeButton linkMode={linkMode} setLinkMode={setLinkMode} />
      <ShortcutTab linkMode={linkMode} userSteamID={userSteamID} setSteamIDPopupIsOpen={setSteamIDPopupIsOpen} />
      <SteamIDPopup currentID={userSteamID} setCurrentID={setUserSteamID} isOpen={steamIDPopupIsOpen} setIsOpen={setSteamIDPopupIsOpen} />
      <ItemSearchBar linkMode={linkMode} itemData={itemData}/>
      <CurrencyCalculator keyValue={keyValue} linkMode={linkMode}/>
      <PageFooter />
    </div>
  );
}


function PageHeader() {
  return (
    <div id="pageHeader">
      <img src={logo} alt="TFtools logo" width={200} height={112} />
      <SplashText />
    </div>
  );
}




function SplashText() {

  const [splashText, setSplashText] = useState("");

  function getRandomSplashText() {
    const splashTexts = [
      "\"Simply the best!\"",
      "Trader's best friend",
      "A key site for refined tastes",
      "Part of a complete breakfast",
      "For all your trading needs",
      "\"Oh no, how many keys is 377 refi-\" Shh. We've got this.",
      "For all your intensive purpoises",
      "As seen on TV!",
      "A personal favourite of pedro.gonsalez2013",
      "Bookmark us to get here quicker!",
      "Engie approved",
      "Used by NASA!",
      "Fastest item search in the west",
      "Because you deserve the best",
      "hat",
      "Consider donating if you like this site!"
    ];

    let randText = splashTexts[Math.floor(Math.random() * splashTexts.length)];
    return randText;
  }

  // Update only on site load, not when parent state changes
  useEffect(() => {
    setSplashText(getRandomSplashText());
  }, []);

  return (
    <div id="splashText">
      <i>{splashText}</i>
    </div>
  );
}



function PageFooter() {

  return (
    <div id="pageFooter">
      Developed by Johann AKA Quesamo <br />
      Copyright 2023 - 2024 <br />
      Powered by Steam and Backpack.tf
    </div>
  );
};







export default App;
