import React, { useState, useEffect } from 'react';


function SteamIDPopup({ currentID, setCurrentID, isOpen, setIsOpen }) {


    // v Separate from currentID because the input field 
    // can contain invalid IDs not to be stored
    const [inputValue, setInputValue] = useState('');

    // Set the value of the field to current ID only on component mount
    useEffect(() => {
        setInputValue(currentID);
    }, [currentID]);


    const openPopup = () => {
        setIsOpen(true);
    };

    const closePopup = () => {
        setIsOpen(false);
    };



    const isValidSteamID64 = (input) => {
        //SteamID64 are 17 digits, all numbers

        // ChatGPT Regex magic
        return (/^\d+$/.test(input) &&
            input.length == 17);
    }


    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleConfirm = () => {
        // inputValue == "" is strictly for debug
        if (isValidSteamID64(inputValue) || inputValue == "") {
            console.log('Selected number:', inputValue);
            setCurrentID(inputValue)
            closePopup();
        }
        else {
            console.log("Invalid steamID ", inputValue);
        }

    };

    return (
        <div>
            {isOpen && (
                <div id="steamIDPopup" className="popup">
                    <div className="popup-content">

                        <h1>Store SteamID?</h1>
                        A SteamID64 can be saved locally to provide shortcuts to things like Steam inventory, trade offer link, and Backpack.tf backpack.
                        <input
                            type="text"
                            id="steamIDField"
                            value={inputValue}
                            onChange={handleInputChange}
                            placeholder={"Enter a SteamID64"}
                        />
                        
                        {/* Frankly an awful way of doing this */}
                        {inputValue ? (isValidSteamID64(inputValue) ? (
                            <div id="steamIDFieldResponse" style={{ color: "green" }}>
                                Valid SteamID64
                            </div>
                        ) :
                            <div id="steamIDFieldResponse" style={{ color: "red" }}>
                                Invalid SteamID64
                            </div>
                        ) :
                            <div id="steamIDFieldResponse" style={{ color: "red", visibility: "hidden" }}>
                                Invalid SteamID64
                            </div>}
                        <button id="steamIDPopupSubmitButton" onClick={handleConfirm}>Save SteamID</button>
                        <button id="steamIDPopupCloseButton" className='popupCloseButton' onClick={closePopup}><b>X</b></button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SteamIDPopup;