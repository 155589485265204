import bptfLogo from "../assets/bptflogo.png";
import scraptfLogo from "../assets/scraptflogo.png";
import mptfLogo from "../assets/mptflogo.png";
import userIcon from "../assets/usericon.png";
import otherShortcutsIcon from "../assets/other_shortcuts_icon.png"
import { useState } from "react";

export default function ShortcutTab({ linkMode, userSteamID, setSteamIDPopupIsOpen }) {

    const shortcuts = {

        "backpacktf": {
            "text": "Backpack.tf",
            "link": "https://backpack.tf/",
            "image": bptfLogo,
            "dropdown": [
                {
                    "text": "Classifieds",
                    "link": "https://backpack.tf/classifieds"
                },
                {
                    "text": "Unusual Pricelist",
                    "link": "https://backpack.tf/unusuals"
                },
                {
                    "text": "Item Calculator",
                    "link": "https://backpack.tf/calculator"
                }
            ],
        },
        "scraptf": {
            "text": "Scrap.tf",
            "link": "https://scrap.tf/",
            "image": scraptfLogo,
            "dropdown": [
                {
                    "text": "Unusual Banking",
                    "link": "https://scrap.tf/unusuals"
                },
                {
                    "text": "Weapon services",
                    "link": "https://scrap.tf/weapons"
                },
                {
                    "text": "MvM services",
                    "link": "https://scrap.tf/partswap"
                },
                {
                    "text": "Raffles",
                    "link": "https://scrap.tf/raffles"
                },

            ],
        },
        "marketplacetf": {
            "text": "Marketplace.tf",
            "link": "https://marketplace.tf/",
            "image": mptfLogo,
            "dropdown": [
                {
                    "text": "Deals",
                    "link": "https://marketplace.tf/deals"
                },
                {
                    "text": "Buy keys",
                    "link": "https://marketplace.tf/items/tf2/5021;6"
                }
            ],
        },
        "other": {
            "text": "Other shortcuts",
            "link": null,
            "image": otherShortcutsIcon,
            "dropdown": [
                {
                    "text": "STN Trading",
                    "link": "https://stntrading.eu/"
                },
                {
                    "text": "Quicksell.store",
                    "link": "https://quicksell.store/",
                },
                {
                    "text": "Mannco.trade",
                    "link": "https://mannco.trade/"
                },
                {
                    "text": "Gladiator time machine",
                    "link": "https://gladiator.tf/time-machine"
                }

            ]
        },
        "user": {
            "text": "User shortcuts",
            "image": userIcon,
            "dropdown": [
                {
                    "text": "BP.tf backpack",
                    "link": `https://backpack.tf/profiles/${userSteamID}`
                },
                {
                    "text": "Trade offer link",
                    "link": `https://steamcommunity.com/profiles/${userSteamID}/tradeoffers/privacy`

                },
                {
                    "text": "Incoming offers",
                    "link": `https://steamcommunity.com/profiles/${userSteamID}/tradeoffers`
                },
                {
                    "text": "Steam inventory",
                    "link": `https://steamcommunity.com/profiles/${userSteamID}/inventory/`
                },
            ],
            "onclick": (() => { setSteamIDPopupIsOpen(true) }),
            "disabled": !userSteamID // Disable this shortcut if there is no steamID saved
        }
    }

    // Set to false when a dropdown is open, to prevent more than one dropdown being open at once
    const [allowDropdownOpening, setAllowDropdownOpening] = useState(true);

    return (
        <div id="shortcutTab">
            {Object.keys(shortcuts).map((key) => (
                <Shortcut text={shortcuts[key].text} link={shortcuts[key].link} image={shortcuts[key].image} dropdown={shortcuts[key].dropdown} disabled={shortcuts[key].disabled} onclick={shortcuts[key].onclick} linkMode={linkMode} userSteamID={userSteamID} allowDropdownOpening={allowDropdownOpening} setAllowDropdownOpening={setAllowDropdownOpening} setSteamIDPopupIsOpen={setSteamIDPopupIsOpen} />
            ))}
        </div >
    )
}


function Shortcut({ image, text, link, dropdown, linkMode, userSteamID, allowDropdownOpening, setAllowDropdownOpening, disabled = false, onclick = null }) {

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    function openDropdown() {
        setIsDropdownOpen(true);
        setAllowDropdownOpening(false);
    }

    function closeDropdown() {
        setIsDropdownOpen(false);
        setAllowDropdownOpening(true);
    }

    function toggleIsDropdownOpen() {
        if (isDropdownOpen) {
            closeDropdown();
        }
        else if (!isDropdownOpen && allowDropdownOpening) {
            openDropdown();
        }
    }


    function handleDropdownButtonMouseOver() {
        openDropdown();
    }

    function handleDropdownButtonMouseOut() {
            closeDropdown();
    }

    function handleDropdownButtonClick() {
        toggleIsDropdownOpen();
    }



    const openDropdownSymbol = "▽";
    const closeDropdownSymbol = "▲";



    return (
        // Note that this top div is for the ENTIRE SHORTCUT -
        // including both base button and dropdown menu

        (
            <div className="shortcut" style={{ filter: disabled ? 'opacity(0.5)' : 'none' }}>
                <div id={text} className="shortcutButton" onClick={onclick}>
                    <a href={link} className="linkArea" target={linkMode === "new tab" ? "_blank" : null}>
                        <img src={image} alt={text} />
                    </a>
                </div>

                {/* This div contains the dropdown and the dropdown button */}
                <div className="dropdownContainer" style={{ zIndex: '15'}}>
                    <div className="dropdownButton"
                        style={{ zIndex: '10', visibility: disabled ? 'hidden' : 'visible' }}
                        onClick={handleDropdownButtonClick}
                        onMouseOver={handleDropdownButtonMouseOver} onMouseOut={handleDropdownButtonMouseOut}>
                        {isDropdownOpen ? closeDropdownSymbol : openDropdownSymbol}
                    </div>
                    <div id="shortcutDropdown" className="dropdown" style={{ display: isDropdownOpen ? 'block' : 'none' }} onMouseOver={handleDropdownButtonMouseOver} onMouseOut={handleDropdownButtonMouseOut}>
                        {dropdown.map((item, index) => (
                            // Mapping out dropdown alternatives
                            <a href={item.link} target={linkMode === "new tab" ? "_blank" : null}>
                                <div className="dropdownEntry" id={item.text} key={index}>
                                    {item.text}
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        )
    );
}
