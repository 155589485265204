import { useState } from "react";
import KeyPriceDisplay from "./KeyPriceDisplay";



export default function CurrencyCalculator({ keyValue, linkMode }) {

  // Consider moving this up in the component hierarchy as fetching is automated
  let keyRate = keyValue;


  // We only store the scrap value, these are the ratios for converting to scrap
  const coefficients = {
    "scrap": 1,
    "rec": 3,
    "ref": 9,
    "keys": keyRate * 9
  };

  const [scrap, setScrap] = useState(0);



  // The event handler for changing inputs
  const updateScrap = (event) => {
    const { id, value } = event.target;
    // id ends in "Field", if we slice that away we can index directly in the coefficients
    let slicedId = id.slice(0, -5);
    // console.log(slicedId, value);
    setScrap(prevScrap => (value * coefficients[slicedId]));

    // console.log("Current scrap: " + scrap);

  }


  // Returns a display value for each currency
  function calcValue(id) {
    let newValue = parseFloat(scrap / coefficients[id]).toFixed(2);
    // console.log("Calculated value for " + id + ": " + newValue);
    return newValue;
  }


  // Returns an object representing the most compact way of expressing the given scrap value
  function getCondensed(scrapValue) {
    // console.log("---------------------")
    let value = scrapValue;

    // In this order because we want to calculate with the 
    // largest currencies first
    var condensedValues = {
      "keys": 0,
      "ref": 0,
      "rec": 0,
      "scrap": 0,
    }

    // Iterative floor division for each currency
    for (const currency in condensedValues) {
      let coefficient = coefficients[currency];
      let amount = Math.floor((value / coefficient));

      // console.log("Currency: " + currency)
      // console.log(value + " scrap // " + coefficient + " = " + amount);

      condensedValues[currency] = amount;
      value -= amount * coefficient;
      // console.log("Remaining scrap: " + value)
    }

    return condensedValues
  }

  // Returns a string based on the object returned by getCondensed,
  // for display purposes
  function getCondensedString() {
    let obj = getCondensed(scrap);

    // Array is joined to produce the string to allow
    // natural placement of ", "
    var currencyArray = [];

    for (const currency in obj) {
      let amount = obj[currency];
      // console.log(currency + " " + amount);
      if (amount > 0) {
        currencyArray.push(amount + " " + currency);
      }

    }
    return currencyArray.join(", ");
  }

  // console.log(getCondensed(50));
  // console.log("String: " + getCondensedString(getCondensed(1000)));


  return (
    <div id="currencyCalcContainer">
      <KeyPriceDisplay keyRate={keyRate} linkMode={linkMode}/>
      <div id="currencyCalculator">
        <h2 id="currencyCalculatorHeader"> Currency calculator</h2>
        <div id="calcBoxes" className="center">
          <div id="scrapBox" className="roundBox">
            <input type="number" id="scrapField" min={0} max={10000} value={calcValue("scrap")} onChange={updateScrap} />
            <label htmlFor="scrapField">SCRAP</label> <br />
          </div>
          <div id="recBox" className="roundBox">
            <input type="number" id="recField" min={0} max={10000} value={calcValue("rec")} onChange={updateScrap} />
            <label htmlFor="recField">RECLAIMED</label> <br />
          </div>
          <div id="refBox" className="roundBox">
            <input type="number" id="refField" min={0} max={10000} value={calcValue("ref")} onChange={updateScrap} />
            <label htmlFor="refField">REFINED</label> <br />
          </div>
          <div id="keysBox" className="roundBox">
            <input type="number" id="keysField" min={0} max={10000} value={calcValue("keys")} onChange={updateScrap} />
            <label htmlFor="keysField">KEYS</label> <br />
          </div>

        </div>
        <div id="condensedDisplay" className={scrap > 0 ? "condensedDisplayVisible" : "condensedDisplayHidden"}> Equal to <b>{getCondensedString()}</b></div>
      </div>
    </div>
  )
};